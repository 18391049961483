import { Put, getAPIUrl } from "../../../services/apiMethod";

export const ChangePassendpoint = {
  changePassApi: `/admin/auth/changePassword`,
};

export const changePassApi = (data, param = "") => {
  const url = getAPIUrl(ChangePassendpoint.changePassApi, param);
  return Put(url, data);
};
