import {Get, getAPIUrl } from "../../../services/apiMethod";

export const EnquiryEndpoint = {
  getEnquiryDetails: `/admin/auth/fetch-enquiry`,
};

export const getEnquiryDetails = (param = "") => {
  const url = getAPIUrl(EnquiryEndpoint.getEnquiryDetails, param);
  return Get(url);
};
