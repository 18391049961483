import React, { useEffect, useState } from "react";
import { Modal, Button, message } from "antd";
import { getLocalStorageItem } from "../../../utils";
import html2pdf from "html2pdf.js";

const ViewBill = ({ isOpen, setIsOpen, Fill }) => {
  const [htmlContent, setHtmlContent] = useState(null);

  const createInvoice = async () => {
    return await fetch(
      `${process.env.REACT_APP_BASE_URL}/admin/report/fetch-bills?salesId=${Fill}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getLocalStorageItem("access_token")}`,
        },
      }
    );
  };

  const handleView = async () => {
    try {
      const response = await createInvoice();
      const result = await response.json();
      const decodedHtml = atob(result.data.htmlBase64);
      setHtmlContent(decodedHtml);
    } catch (err) {
      console.error("Error:", err);
      message.error("Failed to load the bill");
    }
  };

  const downloadPdf = () => {
    if (htmlContent) {
      const element = document.createElement("div");
      element.innerHTML = htmlContent;
      html2pdf()
        .from(element)
        .set({
          filename: "bill.pdf",
          html2canvas: { scale: 2 },
          jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
        })
        .save();
    } else {
      message.error("No bill content available to download.");
    }
  };

  useEffect(() => {
    if (isOpen) {
      handleView();
    }
  }, [isOpen]);

  const handleCancel = () => {
    setIsOpen(false);
  };

  return (
    <Modal
      className="w-full customModal"
      footer={null}
      visible={isOpen}
      onCancel={handleCancel}
      title="Bill Details"
    >
      {htmlContent ? (
        <>
          <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
          <Button type="primary" onClick={downloadPdf}>
            Download PDF
          </Button>
        </>
      ) : (
        <p className="flex justify-center gap-4 m-5 font-semibold text-rgbaprimary6">
          Bill Loading...
        </p>
      )}
    </Modal>
  );
};

export default ViewBill;
