import { Pagination, Spin, Table, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { getEnquiryDetails } from "./EnquiryApifunction";
import { ExtractDate, ExtractTime } from "../../../utils";

const EnquiryDetails = () => {
  const [enquiryData, setenquiryData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalPage, setTotalPages] = useState();
  const [currentPage, setCurrentPage] = useState(1);

  function FetchenquiryDetails(currentPage) {
    setIsLoading(true);
    getEnquiryDetails(`?page=${currentPage}&limit=10`)
      .then((response) => {
        if (response.data.success) {
          setenquiryData(response.data.data);
          setCurrentPage(response.data?.pagination?.currentPage);
          setTotalPages(response.data?.pagination?.totalPages);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log("error", err);
        setIsLoading(false);
      });
  }

  useEffect(() => {
    FetchenquiryDetails(currentPage);
  }, [currentPage]);

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
  };

  const columns = [
    {
      title: " Name",
      dataIndex: "name",
      key: "name",
      render: (data, record) => {
        return (
          <>
            <Tag
              className=" bg-rgbaprimary1 border-rgbaprimary3"
              title={record.name}
            >
              {record.name?.toUpperCase() ?? "--"}
            </Tag>
          </>
        );
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (data, record) => {
        return (
          <>
            <Tag
              className=" bg-rgbaprimary1 border-rgbaprimary3"
              title={record.email}
            >
              {record.email ?? "--"}
            </Tag>
          </>
        );
      },
    },
    {
      title: "Contact",
      dataIndex: "mobile_number",
      key: "mobile_number",
      render: (data, record) => {
        return (
          <>
            <Tag
              className=" bg-rgbaprimary1 border-rgbaprimary3"
              title={record.mobile_number}
            >
              {record.mobile_number ?? "--"}
            </Tag>
          </>
        );
      },
    },
    {
      title: "Message",
      dataIndex: "message",
      key: "message",
      render: (data, record) => {
        return (
          <>
            <Tag
              className=" bg-rgbaprimary1 border-rgbaprimary3"
              title={record.message}
            >
              {record.message ?? "--"}
            </Tag>
          </>
        );
      },
    },
    {
      title: "Created Time",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (data, record) => {
        return (
          <Tag className=" bg-rgbaprimary1 border-rgbaprimary3">
            {ExtractDate(record.createdAt)}.{ExtractTime(record.createdAt)}
          </Tag>
        );
      },
    },
  ];

  return (
    <div>
      <>
        {isLoading ? (
          <div className="text-center mt-52">
            <Spin size="md" />
          </div>
        ) : (
          <>
            {/* <div className="flex items-end justify-between m-4">
              <Search
                placeholder="search  here..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                style={{ width: "25%" }}
              />
            </div> */}

            <div
              style={{ maxHeight: "500px", overflow: "auto" }}
              className="bg-white"
            >
              <Table
                title={() => (
                  <p className="font-medium text-xl p-5 text-primary">
                    Enquiry Details
                  </p>
                )}
                bordered={true}
                columns={columns}
                dataSource={enquiryData}
                pagination={false}
                className="w-screen sm:w-screen"
                size="small"
              />
              <Pagination
                className="mt-2  w-full "
                onChange={handlePageChange}
                current={currentPage}
                defaultCurrent={1}
                total={10 * totalPage}
                showSizeChanger={false}
              />
            </div>
          </>
        )}
      </>
    </div>
  );
};
export default EnquiryDetails;
