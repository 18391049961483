import { Post, Put, getAPIUrl } from "../../../services/apiMethod";
import { Authendpoint } from "./AuthEndpoints";

export const loginApi = (data, param = "") => {
  const url = getAPIUrl(Authendpoint.loginApi, param);
  return Post(url, data, false);
};

export const OtpVerify = (data, param = "") => {
  const url = getAPIUrl(Authendpoint.OtpVerify, param);
  return Post(url, data, false);
};
export const logout = (data, param = "") => {
  const url = getAPIUrl(Authendpoint.logout, param);
  return Post(url, data);
};
