import React, { useState } from "react";
import { Button, Input, Form, Spin, message } from "antd";
import { changePassApi } from "./ChangePassAPi";

function ChangePassword() {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const handlePasswordModify = (values) => {
    setLoading(true);
    const data = {
      password: values.password,
      confirmPassword: values.confirmPassword,
    };
    changePassApi(data)
      .then((response) => {
        if (response.data.status === "success") {
          message.success(response.data.message, 3);
          form.resetFields();
        }
      })
      .catch((error) => {
        console.log(error);
        message.error("An error occurred. Please try again.", 3);
      })
      .finally(() => setLoading(false));
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      form.submit();
    }
  };

  return (
    <>
      {loading ? (
        <div className="text-center mt-52">
          <Spin size="md" />
        </div>
      ) : (
        <>
          <div className="bg-white mb-2 text-primary font-semibold p-6 border-r-2 text-xl">
            Change Password
          </div>

          <div className="p-7 w-72 md:w-1/2 lg:w-1/4 rounded-md bg-white h-auto flex flex-col justify-center">
            <Form
              form={form}
              layout="vertical"
              onFinish={handlePasswordModify}
              onKeyDown={handleKeyPress}
            >
              <Form.Item
                label={
                  <p className="block text-rgbaprimary9 text-md font-medium">
                    Current Password
                  </p>
                }
                name="password"
                rules={[
                  { required: true, message: "Please enter your password!" },
                  {
                    min: 6,
                    message: "Password must be at least 6 characters long!",
                  },
                ]}
              >
                <Input.Password
                  placeholder="Enter password here..."
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-rgbaprimary9 text-sm leading-tight focus:outline-none focus:shadow-outline"
                />
              </Form.Item>
              <Form.Item
                label={
                  <p className="block text-rgbaprimary9 text-md font-medium">
                    New Password
                  </p>
                }
                name="confirmPassword"
                // rules={[
                //   { required: true, message: "Please re-enter your password!" },
                //   ({ getFieldValue }) => ({
                //     validator(_, value) {
                //       if (!value || getFieldValue("password") === value) {
                //         return Promise.resolve();
                //       }
                //       return Promise.reject(
                //         new Error("The two passwords do not match!")
                //       );
                //     },
                //   }),
                // ]}
              >
                <Input.Password
                  placeholder="Re-enter password here..."
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-rgbaprimary9 text-sm leading-tight focus:outline-none focus:shadow-outline"
                />
              </Form.Item>

              <Button
                loading={loading}
                type="primary"
                htmlType="submit"
                className="text-white h-10 bg-gradient-to-tr from-rgbaprimary6 to-rgbaprimary9 w-full font-semibold"
              >
                Change Password
              </Button>
            </Form>
          </div>
        </>
      )}
    </>
  );
}

export default ChangePassword;
