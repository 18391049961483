import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Layout, Menu, Button } from "antd";
import { images } from "../assets/images";
import {
  MdDashboard,
  MdDomainVerification,
  MdOutlineSupervisedUserCircle,
  MdOutlineWallet,
  MdReport,
  MdRequestPage,
} from "react-icons/md";
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
const { Header, Sider, Content } = Layout;
const BasicLayout = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      window.innerWidth <= 600 ? setCollapsed(true) : setCollapsed(false);
    };
    window.addEventListener("resize", handleResize);
    const stored = localStorage.getItem("selected");
    if (stored) {
      setSelectedMenu(stored);
    }
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    localStorage.setItem("selected", selectedMenu);
  }, [selectedMenu]);
  function getlogout() {
    localStorage.clear();
    navigate("/");
  }

  return (
    <Layout className="min-h-screen sidebar">
      <Sider
        style={{ position: "fixed", height: "100%", overflowY: "auto" }}
        collapsible
        collapsed={collapsed}
        onCollapse={() => setCollapsed(!collapsed)}
      >
        <div className="demo-logo-vertical d-flex justify-content-center m-2 ">
          {!collapsed ? (
            <img
              src={images.Logo}
              alt="logo"
              className="w-full mt-9  h-28 flex justify-center  items-center rounded-lg"
            />
          ) : (
            <img
              src={images.Logo}
              alt="logo"
              className="px-2 mt-9"
              style={{ display: "flex" }}
              width={"100px"}
            />
          )}
        </div>
        <Menu
          theme="dark"
          mode="inline"
          className="text-base mt-9 pb-24"
          selectedKeys={[selectedMenu]}
        >
          <Menu.Item
            key="1"
            icon={<MdDashboard />}
            onClick={() => {
              setSelectedMenu("1");
              navigate("/dashboard");
            }}
          >
            Dashboard
          </Menu.Item>
          <Menu.Item
            key="2"
            icon={<MdOutlineSupervisedUserCircle />}
            onClick={() => {
              setSelectedMenu("2");
              navigate("/vendorsDetails", {
                state: {
                  from: "vendors",
                },
              });
            }}
          >
            Vendors Details
          </Menu.Item>
          <Menu.Item
            key="3"
            title=" Inventory Requests"
            icon={<MdRequestPage />}
            onClick={() => {
              setSelectedMenu("3");
              navigate("/inventoryRequests");
            }}
          >
            Inventory Requests
          </Menu.Item>
          <Menu.Item
            key="4"
            icon={<MdOutlineWallet />}
            onClick={() => {
              setSelectedMenu("4");
              navigate("/categoryDetails");
            }}
          >
            Category Details
          </Menu.Item>
          <Menu.Item
            key="5"
            icon={<MdReport />}
            onClick={() => {
              setSelectedMenu("5");
              navigate("/vendorsDetails", {
                state: {
                  from: "reports",
                },
              });
            }}
          >
            Reports
          </Menu.Item>
          <Menu.Item
            key="6"
            icon={<MdDomainVerification />}
            onClick={() => {
              setSelectedMenu("6");
              navigate("/kyc-report", {
                state: {
                  from: "kyc",
                },
              });
            }}
          >
            kYC'S
          </Menu.Item>
          {/* <Menu.Item
            key="6"
            icon={<LogoutOutlined />}
            onClick={() => {
              setSelectedMenu("6");
              getlogout();
            }}
          >
            Sign Out
          </Menu.Item> */}
        </Menu>
      </Sider>
      <Layout>
        <Header style={{ marginLeft: collapsed ? 85 : 205, marginTop: 2 }}>
          <div className="flex bg-rgbaprimary1  px-2 justify-between items-center rounded-md">
            {" "}
            <Button
              type="text"
              icon={
                collapsed ? (
                  <ArrowRightOutlined className="text-rgbaprimary9" />
                ) : (
                  <ArrowLeftOutlined className="text-rgbaprimary9 " />
                )
              }
              onClick={() => setCollapsed(!collapsed)}
              style={{
                fontSize: "22px",
                width: 50,
                height: 50,
                alignSelf: "flex-start",
              }}
            />{" "}
            <p
              title="Logout"
              onClick={getlogout}
              className="bg-[#63b4ce] border-none outline-none text-xs text-white cursor-pointer  px-4 py-2 text-center rounded-md hover:bg-rgbaprimary5"
            >
              <LogoutOutlined /> Sign Out
            </p>
          </div>
        </Header>
        <Content
          className={`bg-rgbaprimary1 h-100 rounded-md p-3 md:p-5 ${
            collapsed ? "ml-[85px]" : "ml-[205px]"
          }`}
        >
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};

export default BasicLayout;
